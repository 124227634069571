import {$get, $post} from "@/utils/http";
let portApi = {
    // 登录
    login (params) {
    return new Promise((resolve, reject) => {
          $post("/member/relation/login", params).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
  // 退出登录
  logout (params) {
    return new Promise((resolve, reject) => {
          $post("/member/relation/logout", params).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
  // 获取验证码
  aliyunSendCode (params) {
    return new Promise((resolve, reject) => {
          $post("/member/relation/aliyunSendCode", params).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
  // 修改头像
  editPicture (params) {
    return new Promise((resolve, reject) => {
          $post(`/member/relation/editPicture?fileName=${params.fileName}`, {}).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
  // 支付宝支付 /member/pay/alipay
  memberPayAlipay (params) {
    return new Promise((resolve, reject) => {
          $post("/member/pay/alipay", params).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
  // 获取用户信息
  getUserInfo (params) {
   return new Promise((resolve, reject) => {
          $get("/member/relation/loginUserAuthInfo", params).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
  
  // 已经付费列表
  memberOrderCurrent (params) {
    return new Promise((resolve, reject) => {
          $post("/member/order/current", params).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
  // 评价列表
  memberEvaluateQuery (params) {
    return new Promise((resolve, reject) => {
          $post("/member/evaluate/query", params).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
  
  // 对商品进行评价
  memberEvaluateAdd (params) {
    return new Promise((resolve, reject) => {
          $post("/member/evaluate/add", params).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
  
  // 获取联系方式
  
  /****** 晴天 *******/
  // 会员行业下拉
  industryList (params) {
   return new Promise((resolve, reject) => {
          $get("/member/industry/list", params).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
  // 会员职业下拉
  occupationList (params) {
   return new Promise((resolve, reject) => {
          $get("/member/occupation/list", params).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
  // 会员接口判断
  memberBasicsEstimateMember (params) {
    return new Promise((resolve, reject) => {
          $post("/member/memberBasics/estimateMember", params).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },

  // 会员基础信息查询列表
  memberBasicsList (params) {
    return new Promise((resolve, reject) => {
          $post("/member/memberBasics/list", params).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
  // 添加会员工作经历
  experienceAdd (params) {
    return new Promise((resolve, reject) => {
          $post("/member/experience/add", params).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
  // 添加会员获奖情况
  honorAdd (params) {
    return new Promise((resolve, reject) => {
          $post("/member/honor/add", params).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
  
  // 添加会员执业证书
  practisingAdd (params) {
    return new Promise((resolve, reject) => {
          $post("/member/practising/add", params).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
  // 添加会员项目经历
  projectAdd (params) {
    return new Promise((resolve, reject) => {
          $post("/member/project/add", params).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
  // 添加会员律师诉讼案例
  lawyerAdd (params) {
    return new Promise((resolve, reject) => {
          $post("/member/lawyer/add", params).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
  // 会员详情信息查询
  memberBasicsParticulars (params) {
    return new Promise((resolve, reject) => {
          $post("/member/memberBasics/particulars", params).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
  // 添加会员信息pk
  memberCompareAdd (params) {
    return new Promise((resolve, reject) => {
          $post("/member/compare/add", params).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
  // 查询会员信息PK列表
  memberCompareList (params) {
   return new Promise((resolve, reject) => {
          $get("/member/compare/list", params).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
  // 批量删除PK会员信息用户
  memberCompareDel (params) {
    return new Promise((resolve, reject) => {
          $post("/member/compare/del", params).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
  // 开始pk(批量查询pk列表)
  memberMemberBasicsMemberbyusers (params) {
    return new Promise((resolve, reject) => {
          $post("/member/memberBasics/memberbyusers", params).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
  
  // 添加收藏
  memberCollectibleAdd (params) {
    return new Promise((resolve, reject) => {
          $post("/member/collectible/add", params).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
  // 取消收藏
  memberCollectibleCancel (params) {
    return new Promise((resolve, reject) => {
          $post("/member/collectible/cancel", params).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
  // 收藏列表
  memberCollectibleList (params) {
    return new Promise((resolve, reject) => {
          $post("/member/collectible/list", params).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
  
  // 查询会员用户收藏夹和pk状态
  memberCollectibleGetstatus (params) {
    return new Promise((resolve, reject) => {
          $post("/member/collectible/getstatus", params).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
  
  // 获取验证码
  memberWechatLogin (params) {
   return new Promise((resolve, reject) => {
          $get("/member/wechat/login", params).then((res) => {
            console.log(res)
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
  
  // 首页列表
  memberHomepageList (params) {
    return new Promise((resolve, reject) => {
          $post("/member/homepage/list", params).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
  // 会员角色字典表下拉接口
  memberOccupationList (params) {
   return new Promise((resolve, reject) => {
          $get("/member/occupation/list", params).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
  //获取用户基本信息接口
  loginUserBasicMessage (params) {
   return new Promise((resolve, reject) => {
          $get("/member/relation/loginUserBasicMessage", params).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
  relationBasicMessage (params) {
    return new Promise((resolve, reject) => {
          $post("/member/relation/basicMessage", params).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
  //阿里云会员支付接口
  memberAlipay (params) {
   return new Promise((resolve, reject) => {
          $get(`/member/memberPay/alipay?totalAmount=${params.totalAmount}&subject=${params.subject}&subjectType=${params.subjectType}`).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
  //根据订单编号查询支付结果
  memberPayQuery (params) {
   return new Promise((resolve, reject) => {
          $get(`/member/memberPay/query?traceNo=${params.traceNo}`).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
  //图片上传
  minioUpload (params) {
    return new Promise((resolve, reject) => {
          $post("/member/file/minio/upload", params, "", {
        contentType: "multipart/form-data",
      }).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
  //首页----问题反馈
  problemFeedback (params) {
    return new Promise((resolve, reject) => {
          $post("/member/mutual/homepage/problemFeedback", params).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },

    //首页----个人详情接口
    personPreview (params) {
    return new Promise((resolve, reject) => {
          $get("/member/person/preview", params).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
  //首页----会员详情接口
  personDetails (params) {
    return new Promise((resolve, reject) => {
          $post("/member/person/details", params).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },

  
}

export default portApi