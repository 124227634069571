<template>
  
  <!-- 
    element-loading-spinner="el-icon-loading" -->
  <div id="app"
    element-loading-text="加载中"
    v-loading.fullscreen.lock="$store.state.loading"
    element-loading-background="rgba(0, 0, 0, 0.2)"
  >
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  mounted(){
    
  }
}
</script>

<style>

</style>
