// 用户接口
import {$get, $post} from "@/utils/http";
let portApi = {
  // 会员行业下拉
  industryList (params) {
    return new Promise((resolve, reject) => {
           $get("/member/industry/list", params).then((res) => {
               if(res && (res.result=="000000" || res.code == 0)){
                   resolve(res)
               }else{
                   reject(res)
               }
           });
       })
   },
    // 添加会员基础信息
  memberBasicsAdd (params) {
    return new Promise((resolve, reject) => {
          $post("/member/memberBasics/add", params).then((res) => {
              if(res && (res.result=="000000" || res.code == 0)){
                  resolve(res)
              }else{
                  reject(res)
              }
          });
      })
  },
  // 编辑会员基础信息
  memberBasicsUpdeta (params) {
    return new Promise((resolve, reject) => {
        $post("/member/memberBasics/updeta", params).then((res) => {
            if(res && (res.result=="000000" || res.code == 0)){
                resolve(res)
            }else{
                reject(res)
            }
        });
    })
  },
  // 添加会员合作意向
  memberPartnerAdd (params) {
    return new Promise((resolve, reject) => {
        $post("/member/partner/add", params).then((res) => {
            if(res && (res.result=="000000" || res.code == 0)){
                resolve(res)
            }else{
                reject(res)
            }
        });
    })
  },
  // 编辑会员合作意向
  memberPartnerUpdeta (params) {
    return new Promise((resolve, reject) => {
        $post("/member/partner/updeta", params).then((res) => {
            if(res && (res.result=="000000" || res.code == 0)){
                resolve(res)
            }else{
                reject(res)
            }
        });
    })
  },
  // 添加会员教育经历信息
  memberEducationAdd (params) {
    return new Promise((resolve, reject) => {
        $post("/member/education/add", params).then((res) => {
            if(res && (res.result=="000000" || res.code == 0)){
                resolve(res)
            }else{
                reject(res)
            }
        });
    })
  },
  // 编辑会员教育经历信息
  memberEducationUpdeta (params) {
    return new Promise((resolve, reject) => {
        $post("/member/education/updeta", params).then((res) => {
            if(res && (res.result=="000000" || res.code == 0)){
                resolve(res)
            }else{
                reject(res)
            }
        });
    })
  },
    // 添加工作经历历信息
    memberWorkAdd (params) {
        return new Promise((resolve, reject) => {
            $post("/member/work/add", params).then((res) => {
                if(res && (res.result=="000000" || res.code == 0)){
                    resolve(res)
                }else{
                    reject(res)
                }
            });
        })
      },
      // 编辑工作经历信息
      memberWorkUpdeta (params) {
        return new Promise((resolve, reject) => {
            $post("/member/work/updeta", params).then((res) => {
                if(res && (res.result=="000000" || res.code == 0)){
                    resolve(res)
                }else{
                    reject(res)
                }
            });
        })
      },
    // 添加项目经历信息
    memberProjectAdd (params) {
        return new Promise((resolve, reject) => {
            $post("/member/project/add", params).then((res) => {
                if(res && (res.result=="000000" || res.code == 0)){
                    resolve(res)
                }else{
                    reject(res)
                }
            });
        })
      },
      // 编辑项目经历信息
      memberProjectUpdeta (params) {
        return new Promise((resolve, reject) => {
            $post("/member/project/updeta", params).then((res) => {
                if(res && (res.result=="000000" || res.code == 0)){
                    resolve(res)
                }else{
                    reject(res)
                }
            });
        })
      },
    // 添加资格证书信息
    memberCertificationAdd (params) {
        return new Promise((resolve, reject) => {
            $post("/member/certification/add", params).then((res) => {
                if(res && (res.result=="000000" || res.code == 0)){
                    resolve(res)
                }else{
                    reject(res)
                }
            });
        })
      },
      // 编辑资格证书信息
      memberCertificationUpdeta (params) {
        return new Promise((resolve, reject) => {
            $post("/member/certification/updeta", params).then((res) => {
                if(res && (res.result=="000000" || res.code == 0)){
                    resolve(res)
                }else{
                    reject(res)
                }
            });
        })
      },
      // 添加获奖经历信息
    memberHonorAdd (params) {
        return new Promise((resolve, reject) => {
            $post("/member/honor/add", params).then((res) => {
                if(res && (res.result=="000000" || res.code == 0)){
                    resolve(res)
                }else{
                    reject(res)
                }
            });
        })
      },
      // 编辑获奖经历信息
      memberHonorUpdeta (params) {
        return new Promise((resolve, reject) => {
            $post("/member/honor/updeta", params).then((res) => {
                if(res && (res.result=="000000" || res.code == 0)){
                    resolve(res)
                }else{
                    reject(res)
                }
            });
        })
      },
      // 添加补充信息信息
      memberReplenishAdd (params) {
        return new Promise((resolve, reject) => {
            $post("/member/replenish/add", params).then((res) => {
                if(res && (res.result=="000000" || res.code == 0)){
                    resolve(res)
                }else{
                    reject(res)
                }
            });
        })
      },
      // 编辑补充信息信息
      memberReplenishUpdeta (params) {
        return new Promise((resolve, reject) => {
            $post("/member/replenish/updeta", params).then((res) => {
                if(res && (res.result=="000000" || res.code == 0)){
                    resolve(res)
                }else{
                    reject(res)
                }
            });
        })
      },
      // 添加律师诉讼案例信息
      memberLawsuitAdd (params) {
        return new Promise((resolve, reject) => {
            $post("/member/lawsuit/add", params).then((res) => {
                if(res && (res.result=="000000" || res.code == 0)){
                    resolve(res)
                }else{
                    reject(res)
                }
            });
        })
      },
      // 编辑律师诉讼案例信息
      memberLawsuitUpdeta (params) {
        return new Promise((resolve, reject) => {
            $post("/member/lawsuit/updeta", params).then((res) => {
                if(res && (res.result=="000000" || res.code == 0)){
                    resolve(res)
                }else{
                    reject(res)
                }
            });
        })
      },
      // 添加项目业绩信息
      memberAchievementAdd (params) {
        return new Promise((resolve, reject) => {
            $post("/member/achievement/add", params).then((res) => {
                if(res && (res.result=="000000" || res.code == 0)){
                    resolve(res)
                }else{
                    reject(res)
                }
            });
        })
      },
      // 编辑项目业绩信息
      memberAchievementUpdeta (params) {
        return new Promise((resolve, reject) => {
            $post("/member/achievement/updeta", params).then((res) => {
                if(res && (res.result=="000000" || res.code == 0)){
                    resolve(res)
                }else{
                    reject(res)
                }
            });
        })
      },
      // 添加资信情况信息
      memberCreditAdd (params) {
        return new Promise((resolve, reject) => {
            $post("/member/credit/add", params).then((res) => {
                if(res && (res.result=="000000" || res.code == 0)){
                    resolve(res)
                }else{
                    reject(res)
                }
            });
        })
      },
      // 编辑资信情况信息
      memberCreditUpdeta (params) {
        return new Promise((resolve, reject) => {
            $post("/member/credit/updeta", params).then((res) => {
                if(res && (res.result=="000000" || res.code == 0)){
                    resolve(res)
                }else{
                    reject(res)
                }
            });
        })
      },
      // 添加机构资质信息
      memberOrgaptitudeAdd (params) {
        return new Promise((resolve, reject) => {
            $post("/member/orgaptitude/add", params).then((res) => {
                if(res && (res.result=="000000" || res.code == 0)){
                    resolve(res)
                }else{
                    reject(res)
                }
            });
        })
      },
      // 编辑机构资质信息
      memberOrgaptitudeUpdeta (params) {
        return new Promise((resolve, reject) => {
            $post("/member/orgaptitude/updeta", params).then((res) => {
                if(res && (res.result=="000000" || res.code == 0)){
                    resolve(res)
                }else{
                    reject(res)
                }
            });
        })
      },
}
export default portApi;